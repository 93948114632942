import { Tolgee, DevTools, FormatSimple, BackendFetch } from '@tolgee/vue'
import { FormatIcu } from '@tolgee/format-icu'
import editorTranslations from '@/i18n/en.editor.json'
import en from '@/i18n/en.json'
import de from '@/i18n/de.json'

export const availableLanguages = ['en', 'de']
export type LanguageCode = (typeof availableLanguages)[number]
export const LS_LANGUAGE_KEY = 'user-locale'

export const languages: Record<LanguageCode, string> = {
  en: 'English',
  de: 'Deutsch',
}

export default Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .use(FormatIcu())
  .init({
    availableLanguages,
    language: import.meta.env.VITE_DEFAULT_LOCALE,
    staticData: {
      'en:local': editorTranslations,
      en,
      de,
    },
  })
