import { initializeApp } from 'firebase/app'
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
  setUserId,
  setUserProperties,
} from 'firebase/analytics'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGEBUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGINGSENDERID,
  appId: import.meta.env.VITE_APPID,
  measurementId: import.meta.env.VITE_MEASUREMENTID,
}
export const firebaseApp = initializeApp(firebaseConfig)

// Initialize Analytics and get a reference to the service
const firebaseAnalytics = getAnalytics(firebaseApp)
setAnalyticsCollectionEnabled(firebaseAnalytics, import.meta.env.VITE_ENVIRONMENT === 'production')

const setAnalyticsProperties = (currentUserId: string) => {
  setUserId(firebaseAnalytics, currentUserId, { global: true })
  setUserProperties(firebaseAnalytics, { currentUserId }, { global: true })
}

export { setAnalyticsProperties }
