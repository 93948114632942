import { createRouter, createWebHistory } from 'vue-router'

import { getAccount } from '@/api'
import { getCookie, AUTH_TOKEN_COOKIE } from '@/helpers/cookie'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:id/:name',
      name: 'PublicPage',
      component: () => import('@/views/PublicPageView.vue'),
    },
    {
      path: '/accounts/overview',
      name: 'CommunityPage',
      component: () => import('@/views/CommunityPageView.vue'),
    },
    {
      path: '/:id/:name/editor',
      name: 'Editor',
      meta: {
        requiresAuth: true,
      },
      component: () => import('@/views/EditorView.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFoundView',
      component: () => import('@/views/NotFoundView.vue'),
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  if (requiresAuth) {
    const authToken = getCookie(AUTH_TOKEN_COOKIE)

    if (authToken) {
      const account = await getAccount()

      if (
        account.data.livePageId === to.params.id &&
        (account.data.accountType === 'business' || account.data.accountType === 'ecommerce')
      ) {
        next()
      } else {
        window.location.href = `${import.meta.env.VITE_FRONTEND_URL}/login`
      }
    } else {
      window.location.href = `${import.meta.env.VITE_FRONTEND_URL}/login`
    }
  } else {
    next()
  }
})

export default router
